<template>
  <v-card outlined :loading="loadings.main_card" id="card_tenant_list_view">
    <v-card-title> 
      {{ currentRouteName }}

      <v-spacer></v-spacer>

      <v-btn
        to="/tenant/manage"
        color="white"
        small
        active-class="red"
      >
      create new
      </v-btn>
    </v-card-title>


    <v-divider></v-divider>
    <tenant-list v-model="loadings.main_card"></tenant-list>
  </v-card>
</template>

<script>
import TenantList from "@/components/tenant/TenantList.vue";
export default {
  data(){
    return {
      loadings: {
        main_card: false
      }
    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    TenantList,
  },
};
</script>

<style>
</style>