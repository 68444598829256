<template>
    <v-virtual-scroll :items="tenants" :item-height="50" height="306">
        <template v-slot:default="{ index, item }">
            <v-list-item>
                <v-list-item-avatar class="my-auto">
                    <v-avatar color="grey">
                        <v-icon color="white">mdi-domain</v-icon>
                    </v-avatar>
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title>
                        {{ item.name }}
                    </v-list-item-title>
                </v-list-item-content>

                <v-spacer/>
                <v-list-item-content v-if="JSON.stringify(item.modules).includes('Warehouse Management System')">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on" >mdi-warehouse</v-icon>
                        </template>
                        <span>Warehouse Management System</span>
                    </v-tooltip>
                </v-list-item-content>
                <v-list-item-content v-else>
                    <!-- keep column order -->
                </v-list-item-content>

                <v-list-item-content v-if="JSON.stringify(item.modules).includes('Marketplace')" class="d-flex flex-column justify-center">
                    <v-list-item-title >
                        <v-tooltip top>
                            <template v-slot:activator="{ on }" >
                                <v-icon v-on="on">mdi-shopping-outline</v-icon>
                            </template>
                            <span>Marketplace</span>
                        </v-tooltip>
                    </v-list-item-title>

                    <v-list-item-subtitle class="text-h6" v-if="item.is_supplier">Supplier</v-list-item-subtitle>
                    <v-list-item-subtitle style="font-size: x-small" v-else>Buyer</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-else>
                    <!-- keep column order -->
                </v-list-item-content>


                <v-list-item-content v-if="JSON.stringify(item.modules).includes('Inventory Management')">
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-icon v-on="on">mdi-order-bool-ascending-variant</v-icon>
                        </template>
                        <span>Inventory Management</span>
                    </v-tooltip>
                </v-list-item-content>
                <v-list-item-content v-else>
                    <!-- keep column order -->
                </v-list-item-content>

                <v-list-item-action>
                    <v-btn depressed small @click="goToTenantDetails(item)">
                        {{ $t("generals.viewDetails") }}

                        <v-icon color="orange darken-4" right> mdi-open-in-new</v-icon>
                    </v-btn>
                </v-list-item-action>
                <v-list-item-action>
                    <v-btn icon @click="confirmDeleteTenant(item)">
                        <v-icon color="error">mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < tenants.length - 1" :key="index"></v-divider>
        </template>
    </v-virtual-scroll>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    /************************************************************************************
     *                                    COMPONENTS
     * **********************************************************************************/
    components: {},

    /************************************************************************************
     *                                    DATA
     * **********************************************************************************/
    data: () => ({
        colors: ["#2196F3"],
        names: ["Oliver", "Jake", "Noah"],
        surnames: ["Smith", "Anderson", "Clark"],
    }),

    /************************************************************************************
     *                                    COMPUTED
     * **********************************************************************************/
    computed: {
        ...mapGetters(["tenants"]),
    },

    /************************************************************************************
     *                                    ACTIONS
     * **********************************************************************************/
    actions: {},

    /************************************************************************************
     *                                    METHODS
     * **********************************************************************************/
    methods: {
        ...mapActions(["deleteTenant"]),

        genRandomIndex(length) {
            return Math.ceil(Math.random() * (length - 1));
        },

        goToTenantDetails(item = null) {
            if (!item) return;
            const self = this;
            const tenantId = item.id;

            //set all subscriptions to false, once the user is redirected to /tenant/manage/
            //the mount method is executed and the subscriptions for the tenant are fetched
            this.initializeSubscriptions();

            if (item.is_supplier) {
                self.$router.push({path: `/supplier/manage/${tenantId}`});
            } else {
                self.$router.push({path: `/tenant/manage/${tenantId}`});
            }

        },

        confirmDeleteTenant(item = null) {
            if (!item) return;
            const self = this;

            self.$swal.fire({
                title: "Do you really want to delete this tenant?",
                text: "All information will be lost",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `Delete`,
                denyButtonText: `Cancel`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    self.value = true;
                    self.$emit("input", self.value);

                    self.deleteTenant(item).finally(() => {
                        self.value = false;
                        self.$emit("input", self.value);
                    });
                }
            });
        },

        initializeSubscriptions() {
            this.$store.commit({
                type: "setSubscriptionsDefaultStatus",
                active: false,
            });

            this.$store.commit({
                type: "setSubscriptionTotalDefaults",
                marketplaceTotal: 0,
                inventoryTotal: 0,
                warehouseTotal: 0,
            })
        }
    },

    /************************************************************************************
     *                                    WATCH
     * **********************************************************************************/
    watch: {},

    /************************************************************************************
     *                                    CREATED
     * **********************************************************************************/
    created() {

    },

    /************************************************************************************
     *                                  MOUNTED
     * **********************************************************************************/
    mounted() {

    },
};
</script>